.header {
    display: flex;
    width: 100%;
    height: 10vh;
    min-height: 60px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background-color: #14171C;
    padding: 0 20px;
    z-index: 1000;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%; 
    margin: 0 auto; 
}

.logo {
    width: 115px;
    height: 55px;
    cursor: pointer;
}

.nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.nav-links span {
    cursor: pointer;
    color: white;
    font-size: 15px;
    font-family: var(--font-primary);
}

.nav-links span:not(:last-child):hover {
    color: #1064B7;
}

.client-portal-btn {
    background: linear-gradient(45deg, #5BDCE5, #359FCD, #0756B1);
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.client-portal-btn:hover {
    background: linear-gradient(45deg, #0756B1, #359FCD, #5BDCE5);
    transition-delay: 1s;
}

.menu-button {
    display: none;
    background: #14171C;
    border: none;
    cursor: pointer;
    margin-right: 25px;
}

.mobile-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 10vh;
    right: 0;
    background: #14171C;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    padding: 10px;
    border-bottom-left-radius: 20px; 

}

.mobile-menu span {
    padding: 10px;
    cursor: pointer;
    color: white;
}

.mobile-menu span:last-child {
    margin-bottom: 15px; 
    width: 60%;
}

.mobile-menu span:not(:last-child):hover {
    background: #20242b;
}


@media (max-width: 480px) {
    .logo {
        margin-left: 20px;
    }

    .nav-links {
        margin-right: 20px;
    }

    .nav-links {
        display: none;
    }

    .menu-button {
        display: block;
    }

    .mobile-menu {
        display: flex;
    }

    .client-portal-btn {
        width: 120px;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .logo {
        margin-left: 20px;
    }

    .nav-links {
        margin-right: 20;
    }

    .nav-links {
        display: none;
    }

    .menu-button {
        display: block;
    }

    .mobile-menu {
        display: flex;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .logo {
        margin-left: 10px;
    }

    .nav-links {
        margin-right: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1535px) {
   .nav-container{
    max-width: 1060px;
   }
  }
  
  @media (min-width: 1536px) {
  .nav-container{
    max-width: 1370px;
   }
  }