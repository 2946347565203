.testimonial-section {
    background-image: url('../../../assets/img/backgrounds/testimonial-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.testimonial-content {
    display: flex;
    flex-direction: Column;
    width: 100%;
    max-width: 890px;
    align-items: center;
    gap: 15px;
    height: auto;
    margin: auto;
}

.tesmonial-text {
    display: flex;
    width: 100%;
    flex-direction: row;
    color: white;
}

.tesmonial-text h2 {
    width: 50%;
    font-size: 35px;
    font-family: var(--font-secondary);
}

.tesmonial-text h3 {
    font-family: var(--font-primary);
    font-size: 18px;
    border-bottom: 2px solid white;
}

.tesmonial-text p {
    font-family: var(--font-primary);
    font-size: 17px;
}


.testimomial-video {
    width: 100%;
    height: 60vh;
    border-radius: 20px;
    background-color: transparent;
}

@media (max-width: 321px) {
    .testimonial-section{
        background-image: url('../../../assets/img/backgrounds/testimonial-phone-bg.jpg');
    }
    .tesmonial-text {
        flex-direction: column;
        align-items: center;
        flex-direction: center;
    }
    .tesmonial-text h2 {
        width: 80%;
        font-size: 17px;
        font-family: var(--font-secondary);
        text-align: center;
    }
    .testimonial-description {
        width: 90%;
        margin-top: 10px;
    }
    .tesmonial-text h3 {
        font-size: 14px;
    }
    .tesmonial-text p {
        font-size: 12px;
    }
    .testimonial-description br {
        display: none;
    }
    .testimomial-video {
        width: 90%;
        height: 40vh;
    }
    .tesmonial-text h2 br:first-of-type {
        display: none;
    }

}

@media (min-width: 321px) and (max-width: 480px) {
    .testimonial-section{
        background-image: url('../../../assets/img/backgrounds/testimonial-phone-bg.jpg');
    }
    .tesmonial-text {
        flex-direction: column;
        align-items: center;
        flex-direction: center;
    }

    .tesmonial-text h2 {
        width: 80%;
        font-size: 20px;
        font-family: var(--font-secondary);
        text-align: center;
    }

    .testimonial-description {
        width: 90%;
        margin-top: 10px;
    }

    .tesmonial-text h3 {
        font-size: 16px;
    }

    .tesmonial-text p {
        font-size: 14px;
    }

    .testimonial-description br {
        display: none;
    }

    .testimomial-video {
        width: 90%;
        height: 40vh;
    }

    .tesmonial-text h2 br:first-of-type {
        display: none;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .testimonial-section{
        background-image: url('../../../assets/img/backgrounds/testimonial-phone-bg.jpg');
    }
    .tesmonial-text {
        flex-direction: column;
        align-items: center;
        flex-direction: center;
    }

    .tesmonial-text h2 {
        width: 80%;
        font-size: 20px;
        font-family: var(--font-secondary);
        text-align: center;
    }

    .testimonial-description {
        width: 90%;
        margin-top: 10px;
    }

    .testimonial-description br {
        display: none;
    }

    .tesmonial-text h3 {
        font-size: 16px;
    }

    .tesmonial-text p {
        font-size: 14px;
    }

    .testimomial-video {
        width: 90%;
        height: 40vh;
    }

    .tesmonial-text h2 br:first-of-type {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .testimonial-section{
        background-image: url('../../../assets/img/backgrounds/testimonial-phone-bg.jpg');
    }
    .tesmonial-text {
        flex-direction: column;
        align-items: center;
        flex-direction: center;
    }

    .tesmonial-text h2 {
        width: 80%;
        font-size: 20px;
        font-family: var(--font-secondary);
        text-align: center;
        margin-bottom: 15px;
    }

    .testimonial-description {
        width: 50%;
        margin-top: 10px;
    }

    .testimonial-description br {
        display: none;
    }

    .tesmonial-text h3 {
        font-size: 17px;
    }

    .tesmonial-text p {
        font-size: 16px;
    }

    .testimomial-video {
        width: 90%;
        height: 50vh;
    }

    .tesmonial-text h2 br:first-of-type {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1080px) {}

@media (min-width: 1081px) and (max-width: 1920px) {}

@media (min-width: 1920px) {}