.hero-section {
    display: flex;
    width: 100%;
    margin-top: 10vh;
    background-image: url('../../../assets/img/backgrounds/herosection-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 90vh;
}

.hero-section-content {
    display: flex;
    justify-content: start;
    align-items: center;
    max-width: 1370px;
    width: 100%;
    margin: auto;
}

.card {
    height: 400px;

}

.card h1 {
    color: white;
    font-size: 50px;
    font-family: var(--font-secondary);
}

.card h2 {
    color: #f1f1f1;
    font-family: var(--font-primary);
    font-size: 19px;
    font-weight: normal;

}


@media (max-width: 321px) {
    .hero-section-content {
        justify-content: center;
    }

    .card {
        margin-right: 0;
        height: 280px;
    }

    .card h1 {
        font-size: 23px;
    }

    .card h2 {
        font-size: 13px;
        margin-top: 170px;
        font-weight: bold;
    }
}


@media (min-width: 321px) and (max-width: 480px) {
    .hero-section-content {
        justify-content: center;
    }

    .card {
        margin-right: 0;
    }

    .card h1 {
        font-size: 25px;
    }

    .card h2 {
        font-size: 15px;
        margin-top: 270px;
        font-weight: bold;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .card {
        height: auto;
        margin-left: 60px;
    }

    .card h1 {
        font-size: 25px;
    }

    .card h2 {
        font-size: 14px;
        margin-top: 40px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    .card {
        margin-left: 20px;
        height: 400px;
    }

    .card h1 {
        font-size: 37px;
    }

    .card h2 {
        font-size: 15px;
        margin-top: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1535px) {
    .hero-section-content {
        max-width: 1060px;
    }

    .card {
        margin-left: 20px;
    }

    .card h2 {
        margin-top: 40px;
    }
}

@media (min-width: 1536px) and (max-width: 1920px) {
    .card {
        margin-left: 130px;
    }

    .card h2 {
        margin-top: 40px;
    }
}

@media (min-width: 1920px) {

    .card {
        margin-left: 100px;
    }

    .card h2 {
        margin-top: 40px;
    }
}