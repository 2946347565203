.about-container {
    background-image: url('../../../assets/img/backgrounds/about-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    width: 100%;
    margin-top: 10vh;
}

.about-content {
    max-width: 1300px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: start;
    flex-direction: column;
    min-height: 90vh;
    color: white;
}

.about-content h2 {
    font-size: 32px;
    margin-left: 20%;
    font-family: var(--font-secondary);
}

.about-text {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.about-text h3 {
    font-size: 27px;
    font-weight: normal;
    font-family: var(--font-primary);
}

.about-text p {
    font-size: 17px;
    margin-top: 5px;
    font-family: var(--font-primary);
}

.about-values {
    margin-left: 50%;
    margin-bottom: -7vh;
}

.about-vision {
    margin-left: 25%;
}

.about-mission {
    margin-bottom: 25vh;
}

@media (max-width: 321px) {
    .about-values {
        margin-left: 0;
        margin-bottom: 0;
    }

    .about-vision {
        margin-left: 0;
    }

    .about-mission {
        margin-bottom: 0;
    }

    .about-content {
        justify-content: center;
        align-items: center;
    }

    .about-content h2 {
        font-size: 17px;
        margin-left: 0;
        margin-bottom: 5vh;
    }

    .about-text {
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8vh;
    }

    .about-text p {
        font-size: 12px;
    }

    .about-text h3 {
        font-size: 15px;
    }
}


@media (min-width: 322px) and (max-width: 480px) {
    .about-values {
        margin-left: 0;
        margin-bottom: 0;
    }

    .about-vision {
        margin-left: 0;
    }

    .about-mission {
        margin-bottom: 0;
    }

    .about-content {
        justify-content: center;
        align-items: center;
    }

    .about-content h2 {
        font-size: 25px;
        margin-left: 0;
        margin-bottom: 7vh;
    }

    .about-text {
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8vh;
    }

    .about-text p {
        font-size: 15px;
    }

    .about-text h3 {
        font-size: 20px;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .about-values {
        margin-left: 0;
        margin-bottom: 0;
    }

    .about-vision {
        margin-left: 0;
    }

    .about-mission {
        margin-bottom: 0;
    }

    .about-content {
        justify-content: center;
        align-items: center;
    }

    .about-content h2 {
        font-size: 25px;
        margin-left: 0;
        margin-bottom: 10vh;
    }

    .about-text {
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8vh;
    }

    .about-text p {
        font-size: 15px;
    }

    .about-text h3 {
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .about-values {
        margin-left: 0;
        margin-bottom: 0;
    }

    .about-vision {
        margin-left: 0;
    }

    .about-mission {
        margin-bottom: 0;
    }

    .about-content {
        justify-content: center;
        align-items: center;
    }

    .about-content h2 {
        font-size: 30px;
        margin-left: 0;
        margin-bottom: 10vh;
    }

    .about-text {
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8vh;
    }

    .about-text p {
        font-size: 17px;
    }

    .about-text h3 {
        font-size: 27px;
    }
}


@media (min-width: 992px) and (max-width: 1535px) {
    .about-content {
        max-width: 1040px;
    }

    .about-container h2 {
        margin-left: 25%;
    }

    .about-vision {
        margin-left: 30%;
    }

    .about-values {
        margin-left: 60%;
    }
}

@media (min-width: 1536px) and (max-width: 1919px) {
    .about-container h2 {
        margin-left: 32%;
    }

    .about-values {
        margin-left: 65%;
        margin-bottom: -4vh;
    }

    .about-vision {
        margin-left: 35%;
        margin-bottom: 5vh;
    }

    .about-mission {
        margin-bottom: 25vh;
    }
}

@media (min-width: 1920px) {
    .about-container h2 {
        margin-left: 30%;
    }

    .about-values {
        margin-left: 65%;
        margin-bottom: -3vh;
    }

    .about-vision {
        margin-left: 34%;
        margin-bottom: 5vh;
    }

    .about-mission {
        margin-bottom: 30vh;
    }
}