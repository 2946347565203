.service-packages-container {
    min-height: 90vh;
    max-width: 1060px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.service-packages {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.card1 {
    width: calc(55% - 20px);
    height: 45vh; 
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
}

.card1 h2 {
    height: 50px;
    color: white;
    font-size: clamp(2rem, 1.5vw, 2rem);
    font-family: var(--font-primary);
}

.plans {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}


.plan-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 98%;
    color: white;
    font-size: 20px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

}
.description span{
    font-size: clamp(1.7rem, 1.2vw, 1.25rem);
    font-weight: bold;
    font-family: var(--font-primary);
    align-items: start;
    margin-bottom: 10px;
}

.description p {
    font-size: clamp(0.9rem, 0.8vw, 0.875rem);
    color: rgb(247, 244, 244);
    text-align: start;
    margin-right: 10px;
    font-family: var(--font-primary);
    word-spacing: 3px;
    line-height: 1.4;
}

.plan-item.active {
    background-color: #0e0149;
}

.plan-item img {
    width: clamp(30px, 3vw, 60px);
    height: clamp(30px, 3vw, 60px);
    margin-left: 10px;
    margin-right: 1px;
    align-self: start;
}

.talk-spaceman {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: end;
    margin-right: 90px;
    align-items: center;
    color: white;
}

.talk-spaceman button {
    background-color: black;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    font-family: var(--font-primary);
}


.talk-spaceman button:hover {
    background-color: #0e0149;
}

@media (max-width: 480px) {
    .service-packages-container {
        display: none;
    }

}

@media (min-width: 480px) and (max-width: 768px) {
    .service-packages-container {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .service-packages-container {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1920px) {
    .service-packages-container {
        display: flex;
    }
}

@media (min-width: 1920px) {
    .service-packages-container {
        max-width: 1200px;
    }

    .service-packages-container {
        display: flex;
    }
}