.logo-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.logo-strip {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    animation: scroll 20s linear infinite;
}

.logo-strip img {
    height: 12vh;
    margin: 0 10px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}