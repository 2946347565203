.contact-section {
  min-height: 80vh;
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-title {
  width: 85%;
  background: linear-gradient(45deg, #094CAF, #7168CC, #D983E9);
  color: white;
  padding: 12px 20px;
  margin-bottom: 24px;
  font-family: var(--font-primary);
  cursor: pointer;
  font-size: 3.5rem;
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
}

.contact-section-content {
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 90%;
  justify-content: center;
  align-items: start;
  gap: 10px;
  margin-top: 20px;
}

.contact-description {
  width: calc(50% - 30px);
  height: 60vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
}

.contact-description p {
  color: white;
  display: flex;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
  font-family: var(--font-primary);
}

.contact-description img {
  width: 30%;
  margin-top: 20px;
  object-fit: cover;
  height: 45%;
  position: absolute;
}

@media (max-width:1600px){
  .contact-description img{
    width: 40%;
  }
}

/* @media (max-width:1400px){
  .contact-description img{
    width: 50%;
  }
} */

@media (max-width:1200px){
  .contact-description img{
    width: 50%;
  }
}

@media (max-width:993px){
  .contact-section-content{
    align-items: center;
  }

  .contact-description img{
    width: 60%;
  }
}
@media (max-width: 480px) {
  .contact-section-content {
    flex-direction: column;
    margin-top: 20px;
    gap: 30px;
  }

  .contact-description {
    width: 80%;
    height: auto;
  }

  .contact-title {
    font-size: 15px;
    width: 80%;
  }

  .contact-description p {
    text-align: center;
    font-size: 14px;
  }

  .contact-description img {
    display: none;
  }
  .contact-section {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .contact-section-content {
    flex-direction: column;
    margin-top: 5px;
    gap: 10px;
  }

  .contact-description {
    width: 70%;
    background-position: center;
  }

  .contact-title {
    font-size: 1rem;
    width: 70%;
  }

  .contact-description{
    height: 370px;
  }
  .contact-description p {
    text-align: center;
    font-size: 14px;
  
  }
  .contact-section {
    margin-bottom: 20px;
  }


}

@media (min-width: 768px) and (max-width: 992px) {
  .contact-section-content {
    flex-direction: column;
    margin-top: 5px;
    gap: 10px;
  }

  .contact-description {
    width: 60%;
    height: 370px;
  }

  .contact-title {
    width: 60%;
    font-size: 1.5rem;
  }
  .contact-section {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1535px) {}


@media (min-width: 1536px) and (max-width: 1920px)  {
  .contact-description{
    height: 55vh;
  }
}

@media (min-width: 1920px) {
  .contact-description{
    height: 55vh;
  }
  .contact-section {
    max-width: 1200px;
  }
}