.info-section {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
}

.video-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.video-item-container {
  position: relative;  
  width: 33.33%;
  text-align: center;
}

.video-title {
  position: absolute;
  top: 15px;  
  left: 50%;
  width: max-content;
  transform: translateX(-50%);  
  font-size: larger;
  font-weight: bolder;
  color: white; 
  -webkit-text-stroke: 1px black; 
  padding: 5px;
  border-radius: 5px;
  z-index: 2;  
}

.video-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



@media (max-width: 480px) {
  .logo-strip {
    justify-content: space-between;
  }

  .logo-strip img {
    height: 9vh;
    margin: 0 5px;
  }

  .video-container {
    flex-direction: column;
    height: 81vh;
  }

  .video-item-container {
    width: 100%;
    height: 27vh;
  }
   .video-title {
    font-size: 20px;
  }
 
}

@media (min-width: 480px) and (max-width: 768px) {
  .logo-strip {
    justify-content: space-between;
  }

  .logo-strip img {
    height: 9vh;
    margin: 0 5px;
  }

  .video-container {
    flex-direction: column;
    height:  81vh;;
  }

  .video-item-container {
    width: 100%;
    height: 27vh;
  }
   
  .video-title {
    font-size: 18px;
  }
 

}

@media (min-width: 768px) and (max-width: 992px) {
  .logo-strip {
    justify-content: space-between;
  }

  .logo-strip img {
    height: 9vh;
    margin: 0 5px;
  }

  .video-container {
    flex-direction: column;
    height:  81vh;;
  }

  .video-item-container {
    width: 100%;
    height: 27vh;
  }
   
  .video-title {
    font-size: 20px;
  }

}

@media (min-width: 992px) and (max-width: 1920px) {
  .video-container {
    height: 78vh;
  }

  .video-title {
    font-size: 22px;
  }
}

@media (min-width: 1920px) {
  .video-container {
    height: 78vh;
  }
  .video-title {
    font-size: 25px;
  }
}