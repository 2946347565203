.founders-section {
    min-height: 90vh;
    width: 100%;
    max-width: 1360px;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.founders-section h2 {
    background-color: #f1f1f1;
    color: black;
    padding: 8px 20px;
    border-radius: 10px;
    font-family: var(--font-primary);
    cursor: pointer;
    font-size: 18px;
}

.founders-section-content {
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.sponsors {
    display: flex;
    flex-direction: column;
}

.sponsors-card {
    height: auto;
    width: 150px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    margin-bottom: 15px;
}

.sponsors-card img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.sponsors-card h3 {
    font-size: 12px;
    font-family: var(--font-primary);
    text-align: center;
}

.sponsors-card p {
    font-size: 10px;
    font-family: var(--font-primary);
    text-align: center;
}

.founders {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 130px;

}

.founders-row {
    display: flex;
    justify-content: space-around;
    gap: 50px;
}


.founders-card {
    height: auto;
    width: 150px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.founders-card h3 {
    font-size: 12px;
    font-family: var(--font-primary);
    text-align: center;
}

.founders-card p {
    font-size: 10px;
    font-family: var(--font-primary);
    text-align: center;
}


@media (max-width: 480px) {
    .founders-section h2 {
        margin-top: 50px;
    }

    .sponsors {
        display: none;
    }

    .founders {
        margin-top: 30px;
    }

    .founders-row {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

}

@media (min-width: 480px) and (max-width: 767px) {
    .founders-section h2 {
        margin-top: 50px;
    }

    .sponsors {
        display: none;
    }

    .founders {
        margin-top: 30px;
    }

    .founders-row {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .founders-section-content {
        flex-direction: column;
        gap: 0px;
    }

    .sponsors {
        flex-direction: row;
        justify-content: center;
        margin-top: 70px;
    }

    .founders {
        margin-top: 70px;
    }
}


@media (min-width: 992px) and (max-width: 1535px) {
    .founders-section h2{
        font-size: 20px;
    }
    .founders-section-content {
        gap: 50px;
    }
    .founders-card{
        width: 200px;
    }
    .founders{
        gap: 50px;
    }
    .founders-row{
        gap: 50px;
    }

    .founders-card h3{
        font-size: 14px;
    }
    .founders-card p{
        font-size: 12px;
    }
    .sponsors-card{
        width: 200px;
    }
    .sponsors-card h3{
        font-size: 14px;
    }
    .sponsors p{
        font-size: 12px;
    }
}

@media (min-width: 1535px)  {
    .founders-section h2{
        font-size: 22px;
        margin-bottom: 3vh;
    }
    .founders-section-content {
        gap: 100px;
    }
    .founders-card{
        width: 215px;
    }
    .founders{
        gap: 50px;
    }
    .founders-row{
        gap: 80px;
    }

    .founders-card h3{
        font-size: 16px;
    }
    .founders-card p{
        font-size: 13px;
    }
    .sponsors-card{
        width: 215px;
    }
    .sponsors-card h3{
        font-size: 16px;
    }
    .sponsors p{
        font-size: 13px;
    }
}
