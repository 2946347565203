footer {
    width: 100%;
    background-color: #14171C;
    color: #436ce2;
}

.footer-container {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1400px;
}

#footer_image{
    width: 100%;
}

#footer_image img {
    width: 300px;
    margin-left: 5em;
    margin-top: 1em;
}

#footer_content ul {
    box-sizing: border-box;
    margin-left: 5em;
}

.footer-link{
    text-decoration: none;
    cursor: pointer;
}


#footer_content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem;
}

.footer-list {
    display: flex;
    flex-direction: column;
    gap: 0.50rem;
    list-style: none;
}

.footer-list .footer-link {
    color: #9E9CA2;
    transition: all 0.4s;
}

.footer-list .footer-link:hover {
    color: #46424D;
}

footer hr{
    display: flex;
    border: 1px solid #525153;
    margin: 5px 30px;
    justify-content: center;
}

#footer_copyright {
    display: flex;
    justify-content: space-between;
    color: #525153;
    font-size: 0.9rem;
    margin: 5px 30px;
    padding-bottom: 0.5rem;
    font-weight: 100;
}
@media screen and (max-width: 1535px) {
    .footer-container{
     max-width: 1060px;
    }
    img {
        width: 90%;
    }
   }


@media screen and (max-width: 640px) {
    i{
    right: 7rem;
    width: 25px;
    height: 25px;
    margin-left: 22rem;
    }
    img {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    
    #footer_content {  
        grid-template-columns: repeat(2, 1fr);   
        gap: 0.75rem;  
    }

    #footer_image img {
        width: 100%;
    }



}     
/* Para celulares*/
@media screen and (max-width: 426px) {


    i{
        right: 5rem;
    }
    #footer_content {
        margin-left: 10px;
        grid-template-columns: repeat(1, 1fr);
        padding: 2rem 1rem;
    }

    #footer_image img{
        width: 200px;
    }
}