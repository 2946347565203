.history-section {
    background-image: url('../../../assets/img/backgrounds/history-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.history-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1060px;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
    height: auto;
    margin: auto;
}

.text-div {
    width: calc(65% - 20px);
    height: 65vh;
    padding: 20px;
    box-sizing: border-box;
    color: white;
}

.text-div h2 {
    font-size: calc(1vw + 1vh);
    margin-bottom: 20px;
    font-family: var(--font-primary);
}

.text-div p {
    font-size: calc(0.4vw + 0.9vh);
    line-height: 1.5;
    margin-bottom: 15px;
    text-align: justify;
    font-family: var(--font-primary);
    text-indent: 20px;
}

.image-div {
    width: calc(35% - 20px);
    height: 65vh;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.image-div img {
    height: 350px;
    object-fit: cover;
}

@media (max-width: 321px) {
    .image-div {
        display: none;
    }

    .text-div {
        width: 90%;
    }

    .text-div h2 {
        font-size: 13px;
        margin-bottom: 3vh;
    }

    .text-div p {
        font-size: 8px;
        margin-bottom: 2vh;
    }

    .history-content {
        gap: 0px;
        justify-content: center;
    }
}


@media (min-width: 322px) and (max-width: 480px) {
    .image-div {
        display: none;
    }

    .text-div {
        width: 90%;
    }

    .text-div h2 {
        font-size: 15px;
        margin-bottom: 3vh;
    }

    .text-div p {
        font-size: 10px;
        margin-bottom: 2vh;
    }

    .history-content {
        gap: 0px;
        justify-content: center;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .image-div {
        display: none;
    }

    .text-div {
        width: 90%;

    }

    .history-content {
        gap: 0px;
        justify-content: center;
       height: auto;
    }

    .text-div h2 {
        font-size: 18px;
    }

    .text-div p {
        font-size: 14px;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .image-div {
        display: none;
    }

    .text-div {
        width: 90%;

    }

    .history-content {
        gap: 0px;
        justify-content: center;
    }
    .text-div h2{
        font-size: 20px;
    }
    .text-div p{
        font-size: 16px;
    }

}

@media (min-width: 992px) and (max-width: 1080px) {
    .image-div img {
        height: 280px;
    }
}

@media (min-width: 1081px) and (max-width: 1920px) {}

@media (min-width: 1920px) {}