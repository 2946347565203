.gradient-border {
  padding: 2px;
  background: linear-gradient(to right, #094CAF, #7168CC, #D983E9);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
}

.contact-form {
  width: 100%;
  background-color: #000000;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 10px; /* Adicione padding para espaçamento interno */
  padding-left: 0px;
  padding-right: 0px;
}

.contact-form label {
  color: white;
  font-family: var(--font-primary);
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
  width: 90%;
}

.contact-form input {
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  box-sizing: border-box;
  background-color: white;
  outline: none;
  margin: 4px 10px;
}

.contact-form input:focus {
  border: 2px solid #7168CC;
}

.contact-form button {
  padding: 8px 50px;
  background: linear-gradient(45deg, #094CAF, #7168CC, #D983E9);
  color: white;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.contact-form button:hover {
  background-color: #094CAF;
}

@media (max-width: 480px) {
  .gradient-border {
    width: 80%;
  }

  .contact-form label {
    font-size: 13px;
    margin-top: 15px;
  }

  .contact-form input {
    padding: 8px;
  }

  .contact-form button {
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .gradient-border {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .gradient-border {
    width: 60%;
  }
}

@media (min-width: 1536px) {
  .contact-form {
    height: auto;
  }
}