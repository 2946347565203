.card-services {
    width: 230px;
    height: 270px;
    border-radius: 35px;
    border: 4px solid #333941;
    padding: 5px;
    background-color: #14171C;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: relative;
}

.img-service {
    margin-top: -100px;
}

.blue-strip {
    width: 335px;
    height: 190px;
    position: absolute;
    top: 35px;
    margin-right: 30px;
    z-index: 1;
}

.blue-strip-text {
    position: absolute;
    top: 48%;
    color: white;
    font-size: 12px;
    font-weight: bold;
    width: 230px;
    margin-right: 0px;
    z-index: 2;
    font-family: "Montserrat", serif;
    font-weight: bold;
}

.card-services p {
    font-family: "Montserrat", serif;
    color: white;
    font-size: 11px;
    /* margin-top: 20px; */
    margin-right: 10px;
    margin-left: 10px;
}