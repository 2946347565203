.service-packages-responsive{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media (max-width: 480px) {
    .service-packages-responsive {
        display: flex;
    }

}

@media (min-width: 480px) and (max-width: 768px) {
    .service-packages-responsive {
        display: flex;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .service-packages-responsive {
        display: flex;
    }
}

@media (min-width: 992px) and (max-width: 1920px) {
    .service-packages-responsive {
        display: none;
    }
}

@media (min-width: 1920px) {
    .service-packages-responsive {
        max-width: 1200px;
    }

    .service-packages-responsive {
        display: none;
    }
}

