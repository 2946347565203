.slide-container {
    max-width: 1060px;
}


  
  @media (min-width: 1536px) {
 .slide-container{
    max-width: 1400px ;
 }
  }