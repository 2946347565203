.services-section {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

}

.services-title {
  background: linear-gradient(45deg, #094CAF, #7168CC, #D983E9);
  color: white;
  padding: 8px 20px;
  font-family: var(--font-primary);
  cursor: pointer;
  font-size: 20px;
  font-weight: normal;
  margin-top: 50px;
}

.services-description {
  color: white;
  font-family: var(--font-primary);
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.services-description p {
  margin-bottom: 5px;
}


@media (max-width: 480px) {
  .services-description {
    font-size: 17px;
  }

}

@media (min-width: 480px) and (max-width: 768px) {
  .services-title{
    margin-top: 70px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .services-title{
    margin-top: 150px;
  }
}

@media (min-width: 992px) and (max-width: 1920px) {}

@media (min-width: 1920px) {}