.card-service-package {
    display: flex;
    justify-content: start;
    align-items: start;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    position: relative;
}


.blue-strip-package {
    position: absolute;
    margin-right: 50px;
    z-index: 1;
}

.blue-strip-package-text {
    position: absolute;
    color: white;
    font-weight: 600;
    z-index: 2;
    font-family: var(--font-primary);
    white-space: nowrap; 
    margin-top: 3px;
}

.benefits-list {
    margin-left: 0px;
    margin-right: 14px;
    margin-bottom: 15px;
}

.benefit-item {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
    font-family: var(--font-primary);
}

.benefit-item span {
    color: white;
    /* text-align: justify; */
}

.benefits-list h2{
    color: white;
    margin-top: 15px;
    margin-left: 15px;
    font-family: var(--font-primary);
}

.benefits-list h3{
    color: white;
    font-size: 14px;
    font-weight: normal;
    margin-top: 15px;
    margin-left: 15px;
    font-family: var(--font-primary);
}


.additional-benefits{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    font-family: var(--font-primary);
    
}
.additional-benefits span{
    color: white;
    padding: 3px;
}
