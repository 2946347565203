.slide-container-packages {
    max-width: 1100px;
}

.title-container-packages {
    color: white;
    font-size: 22px;
}

@media (max-width: 480px) {
    .title-container-packages{
        margin-bottom: 5px;
    }

}

@media (min-width: 480px) and (max-width: 768px) {
    .title-container-packages{
        margin-bottom: 0px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .title-container-packages{
        margin-bottom: 45px;
        font-size: 32px;
    }
}


